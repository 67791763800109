import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formItemLayout, tailFormItemLayout } from "../UserPages/CreateUser";
export default function CreateNewWallet() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [walletTypeOptions, setWalletTypeOptions] = useState<any[]>();
  const [assetNetworkList, setAssetNetworkList] = useState<any>();
  const [selectedClient, setSelectClient] = useState<any>();
  const [clientAccountList, setClientAccountList] = useState<any[]>();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [popupVisible, setPopupVisible] = useState(false);
  const [subWallet, setSubWallet] = useState<any>();
  const handleSwitchChange = (checked: boolean, switchId: number) => {
    if (!checked) {
      setSubWallet(form.getFieldValue("subWallets")[switchId]);
      setPopupVisible(true);
    }
  };

  function handleClientSelect(option: any, optionElement: any) {
    setSelectClient(option);
    form.setFieldValue("clientAccountId", undefined);
  }

  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const getWalletTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet-type",
      withCredentials: true,
    })
      .then((res) => {
        setWalletTypeOptions(res.data.walletTypes);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.clients) {
          res.data.clients.forEach(
            (client: any) =>
              (client.label = `${client.label} (${client.deltecAccount})`),
          );
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const fetchAssetApiUrlUtils = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkList(res.data.networks);
    });
  }, []);

  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const fetchSelectOptions = useCallback(() => {
    getClientOptions();
    getAssetOptions();
    getVenueList();
    getWalletTypes();
  }, [getAssetOptions, getClientOptions, getVenueList, getWalletTypes]);

  const onFinish = useCallback((form: any) => {
    setIsCreating(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet",
      withCredentials: true,
      data: {
        clientId: form.clientId,
        clientAccountId: form.clientAccountId,
        walletAddress: form.walletAddress,
        walletTypeId: form.walletTypeId,
        venueId: form.venueId,
        dateCreated: form.dateCreated,
        subWallets: form.subWallets,
        label: form.label,
        isActive: form.isActive,
        isArchived: form.isArchived,
        isClientPersonal: form.isClientPersonal,
        assetNetworkId: form.assetNetworkId ? form.assetNetworkId : null,
      },
    })
      .then((res) => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "Wallet",
            createOrUpdate: "Create",
            displayName: form.label,
            createUrl: "/bo/wallet/create",
            editUrl: "/bo/wallet/update/" + encodeURIComponent(res.data.id),
          },
        });
        toast.success("Wallet Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsCreating(false);
      });
  }, [navigate]);
  useEffect(() => {
    fetchSelectOptions();
    fetchAssetApiUrlUtils();
  }, [fetchSelectOptions, fetchAssetApiUrlUtils]);

  useEffect(() => {
    if (selectedClient && clientOptions) {
      const c = clientOptions?.find((t) => t.id === selectedClient);
      if (c.clientAccounts && c.clientAccounts.length) {
        setClientAccountList(c.clientAccounts);
        form.setFieldValue("clientAccountId", c.clientAccounts[0].value);
      } else {
        setClientAccountList([]);
      }
    }
  }, [selectedClient, clientOptions, form]);

  const [existingWallets, setExistingWallets] = useState<any[]>([]);
  const [showDuplicateConfirmModal, setShowDuplicateConfirmModal] =
    useState<boolean>(false);
  const checkWalletExists = useCallback(
    (form: any) => {
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet-exist",
        withCredentials: true,
        params: {
          walletAddress: form.walletAddress,
          networkId: form.assetNetworkId ? form.assetNetworkId : undefined,
          clientId: form.clientId,
        },
      }).then((res) => {
        if (!res.data.count) {
          onFinish(form);
        } else {
          setExistingWallets(res.data.wallets);
          setShowDuplicateConfirmModal(true);
        }
      });
    },
    [onFinish],
  );

  return (
    <Content id="dcl-create-wallet-page">
      <Row>
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from);
              } else {
                navigate("/bo/wallets/list");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Col>
      </Row>
      <Form
        {...formItemLayout}
        form={form}
        labelWrap
        autoComplete="off"
        onFinish={checkWalletExists}
        // onFinishFailed={onFinishFailed}
        className="form-body"
        labelCol={{
          sm: {
            span: 7,
          },
        }}
        wrapperCol={{
          sm: {
            span: 14,
          },
        }}
        style={{
          padding: "1em",
          margin: "auto",
        }}
      >
        <Divider orientation="left">
          <b>Wallet Info</b>
        </Divider>
        <Row>
          <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
            <Form.Item
              label="Client"
              name="clientId"
              rules={[
                {
                  required: true,
                  message: "Please select client",
                },
              ]}
            >
              <Select
                options={clientOptions}
                onSelect={handleClientSelect}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Form.Item>
            <Form.Item label="Client Account" name="clientAccountId">
              <Select options={clientAccountList} />
            </Form.Item>
            <Form.Item label="Venue" name="venueId">
              <Select
                options={venueOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Form.Item>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: "Please input wallet label",
                },
              ]}
            >
              <Input placeholder="Please enter wallet label" />
            </Form.Item>
            {/* <Form.Item
              label="Is Active"
              name="isActive"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item> */}
          </Col>
          <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
            <Form.Item name="assetNetworkId" label="Network">
              <Select
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={assetNetworkList}
                placeholder="Network (Ignore if Fiat asset)"
              />
            </Form.Item>
            <Form.Item
              label="Address"
              name="walletAddress"
              rules={[
                {
                  required: true,
                  message: "Please input wallet address",
                },
              ]}
            >
              <Input placeholder="Please enter wallet address" />
            </Form.Item>
            <Form.Item
              label="Client Personal Wallet"
              name="isClientPersonal"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Date Created"
              name="dateCreated"
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">
          <b>Subwallets</b>
        </Divider>
        <Row>
          <Col sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
            <Form.List name="subWallets">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <React.Fragment key={key}>
                      <Row>
                        <Col sm={{ span: 6, offset: 1 }}>
                          <Form.Item
                            label="Asset"
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            name={[name, "assetId"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing asset",
                              },
                            ]}
                          >
                            <Select
                              options={assetOptions}
                              showSearch
                              popupMatchSelectWidth={false}
                              filterOption={(input, option: any) => {
                                return option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 6 }}>
                          <Form.Item
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            label="Label"
                            {...restField}
                            name={[name, "label"]}
                          >
                            <TextArea autoSize />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label="Type"
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            rules={[
                              {
                                required: true,
                                message: "Please select wallet type",
                              },
                            ]}
                            name={[name, "walletTypeId"]}
                          >
                            <Select
                              options={walletTypeOptions}
                              showSearch
                              popupMatchSelectWidth={false}
                              filterOption={(input, option: any) => {
                                return option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 6, offset: 1 }}>
                          <Form.Item
                            label="Activation Date"
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            labelCol={{
                              sm: {
                                span: 12,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            name={[name, "activationDate"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing activation date.",
                              },
                            ]}
                            initialValue={dayjs()}
                          >
                            <DatePicker />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 20,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 4,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="Is Active"
                            name={[name, "isActive"]}
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch
                              size="small"
                              onChange={(checked) =>
                                handleSwitchChange(checked, key)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 3 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 20,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 4,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="In Report"
                            name={[name, "isReport"]}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Switch size="small" />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 18,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 6,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="Is Placeholder"
                            name={[name, "isPlaceholder"]}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 1 }}>
                          <Popconfirm
                            title="Are you sure to delete this sub-wallet?"
                            onConfirm={() => {
                              remove(name);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MinusCircleOutlined onClick={() => {}} />
                          </Popconfirm>
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  ))}
                  <Form.Item
                    wrapperCol={{
                      sm: {
                        span: 12,
                        offset: 6,
                      },
                    }}
                  >
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({ newlyAdded: true, edited: false });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add New Asset
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Form.Item {...tailFormItemLayout}>
          <Button
            htmlType="submit"
            className="submit-button primary-button"
            loading={isCreating}
          >
            Create Wallet
          </Button>
        </Form.Item>
      </Form>
      <Modal
        destroyOnClose={true}
        open={popupVisible}
        onOk={() => setPopupVisible(false)}
        onCancel={() => {
          setPopupVisible(false);
          subWallet.archivedDate = undefined;
          subWallet.isActive = true;
        }}
      >
        <h2 style={{ color: "white" }}>
          {subWallet && subWallet.label
            ? `${subWallet ? subWallet.label : ""} (${
                subWallet && subWallet.asset ? subWallet.asset.ticker : ""
              })`
            : ""}
        </h2>
        <Row>
          <Col span={8}>
            <p>Archived Date: </p>
          </Col>
          <Col span={12}>
            <DatePicker
              onChange={(value) => {
                subWallet.archivedDate = value?.toDate();
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>Memo: </p>
          </Col>
          <Col span={12}>
            <TextArea
              onChange={(e) => {
                subWallet.memo = e.target.value;
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        destroyOnClose={true}
        open={showDuplicateConfirmModal}
        onOk={() => onFinish(form.getFieldsValue())}
        cancelText="No"
        okText="Yes"
        cancelButtonProps={{
          loading: isCreating,
        }}
        okButtonProps={{ loading: isCreating }}
        onCancel={() => {
          setShowDuplicateConfirmModal(false);
        }}
      >
        <Alert
          style={{
            marginTop: "2 em",
            marginBottom: "1em",
          }}
          message={
            <React.Fragment>
              <p style={{ color: "black" }}>
                <b>This address already exists in our records:</b>
              </p>
              {existingWallets.map((wallet, index) => (
                <p
                  style={{ color: "black", marginLeft: "2em" }}
                  key={wallet.id}
                >
                  <b>{index + 1}.</b> {wallet.walletAddress} -{" "}
                  {wallet.client?.clientName}
                  {wallet?.assetNetwork
                    ? ` - ${wallet?.assetNetwork.name}`
                    : ""}
                </p>
              ))}
              <p style={{ color: "black" }}>
                <b>Do you confirm the creation?</b>
              </p>
            </React.Fragment>
          }
          type="warning"
        />
      </Modal>
    </Content>
  );
}
